<template>
  <div class="skeleton-index-main">
    <div class="slider">
      <div class="slider-items">
        <Skeletor class="slider" as="div" />
        <Skeletor class="slider" as="div" />
      </div>

      <div class="slider-dots">
        <Skeletor class="dots" as="div" />
      </div>
    </div>

    <div class="categories">
      <Skeletor
        v-for="n in CATEGORIES_COUNT"
        :key="n"
        class="category"
        as="div"
      />
    </div>

    <div class="top-events">
      <Skeletor class="tabs" as="div" />
      <div class="sport-categories">
        <Skeletor
          v-for="n in SPORT_CATEGORIES_COUNT"
          :key="n"
          class="sport-category"
          as="div" />
      </div>

      <div class="events">
        <Skeletor
          v-for="n in TOP_EVENTS_COUNT"
          :key="n"
          class="event"
          as="div" />
      </div>
    </div>

    <div
      v-for="m in GAMES_GROUPS"
      :key="m"
      class="games-group"
    >
      <div class="header">
        <Skeletor class="title" as="div" />
        <Skeletor class="show-all" as="div" />
      </div>
      <div class="games">
        <Skeletor
          v-for="n in GAMES_COUNT"
          :key="n"
          class="game"
        />
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';

  const CATEGORIES_COUNT = 3;
  const TOP_EVENTS_COUNT = 3;
  const SPORT_CATEGORIES_COUNT = 6;
  const GAMES_GROUPS = 2;
  const GAMES_COUNT = 9;
</script>

<style src="~/assets/styles/components/skeleton-preloader/index-page.scss" lang="scss" />
